import m from '@otovo/shared/components/Navigation/messages';
import { NavbarLink } from '@otovo/shared/components/Navigation/types';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { getBlogLink } from '@otovo/shared/lib/getBlogLink';
import { Otovo$Locale } from '@otovo/shared/types/otovoweb';
import { useRouter } from 'next/router';
import { useLocatorPages } from '../components/Sanity/LocatorPages/useLocatorPages';

export function useNavbarLinks(): Array<NavbarLink> {
  const locatorPages = useLocatorPages();
  const config = useGlobalConfig();
  const { market } = config.BU_CONFIG;
  const { locale } = useRouter() as { locale: Otovo$Locale };

  const links = [] as Array<NavbarLink>;

  if (market === 'fr') {
    links.push({ to: '/', intlKey: m.storefront });
  }
  links.push({ to: '/about/', intlKey: m.aboutUs });
  links.push({ to: config.MYPAGE_URL, intlKey: m.myPage });

  const blogLink = getBlogLink(config);

  if (blogLink) {
    links.push(blogLink);
  }

  if (locatorPages?.length) {
    links.push({ to: '/locator/', intlKey: m.locatorPageTitle });
  }

  if (config.HUBSPOT_KNOWLEDGE_BASE_URL) {
    links.push({
      to: config.HUBSPOT_KNOWLEDGE_BASE_URL,
      intlKey: m.faqTitle,
      nofollow: true,
    });
  }
  links.push({ to: '/support/', intlKey: m.support });

  if (market === 'fr') {
    links.push(
      { to: '/a/location-panneau-solaire/', intlKey: m.leasingInstallation },
      { to: '/a/offre/', intlKey: m.buyNowInstallation },
    );
  }

  links.push({ to: '/partner/', intlKey: m.partnerPageTitle });

  links.push({
    to: `${config.INSTALLER_WEB_URL}/signup/${locale}/`,
    intlKey: m.becomeAnInstaller,
  });

  if (config.urls.referralPageUrl) {
    links.push({
      to: `${config.urls.referralPageUrl}`,
      intlKey: m.otovoReferralProgram,
    });
  }

  return links;
}
