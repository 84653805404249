import sanityClient from 'picosanity';
import throwForFalsy from '../throwForFalsy';

type SanityClientConfig = {
  projectId: string;
  dataset: string;
  useCdn: boolean;
};

const defaultOpts = { useCdn: true };

export function getSanityClientConfig({
  useCdn,
} = defaultOpts): SanityClientConfig {
  return {
    projectId: throwForFalsy(process.env.NEXT_PUBLIC_SANITY_PROJECT_ID),
    dataset: throwForFalsy(process.env.NEXT_PUBLIC_SANITY_DATASET),
    useCdn,
  };
}

export function getSanityClient(opts: typeof defaultOpts = defaultOpts) {
  return sanityClient(getSanityClientConfig(opts));
}
