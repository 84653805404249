import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import cx from 'classnames';
import { Fragment } from 'react';

type Props = {
  isOpen: boolean;
  setOpen: (state: boolean) => void;
  title?: React.ReactNode;
  children: React.ReactNode;
  variant?: 'dark' | 'light';
};

const SlideOver = ({
  isOpen,
  setOpen,
  title,
  children,
  variant = 'dark',
}: Props) => {
  const styles = {
    dark: {
      background: 'bg-grey-90',
      title: 'text-lilac-20',
      closeButton: 'bg-lilac-20 focus:ring-grey-50',
    },
    light: {
      background: 'bg-white',
      title: 'text-heading',
      closeButton: 'bg-grey-10 focus:ring-grey-30',
    },
  };
  const currentStyles = styles[variant];

  return (
    <Transition show={isOpen}>
      <Dialog as="div" className="relative z-40" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-md">
                  <div
                    className={cx(
                      currentStyles.background,
                      'flex h-full flex-col overflow-y-scroll py-6 shadow-xl',
                    )}
                  >
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <DialogTitle
                          className={cx(
                            currentStyles.title,
                            'text-lg font-medium',
                          )}
                        >
                          {title}
                        </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className={cx(
                              currentStyles.closeButton,
                              'rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-offset-4 active:bg-lilac-40',
                            )}
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="h-5 w-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 19.5l15-15m-15 0l15 15"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {children}
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default SlideOver;
